// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$affiliate-ui-demo-primary: mat.define-palette(mat.$indigo-palette);
$affiliate-ui-demo-accent: mat.define-palette(
  mat.$pink-palette,
  A200,
  A100,
  A400
);

// The warn palette is optional (defaults to red).
$affiliate-ui-demo-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$affiliate-ui-demo-theme: mat.define-light-theme(
  (
    color: (
      primary: $affiliate-ui-demo-primary,
      accent: $affiliate-ui-demo-accent,
      warn: $affiliate-ui-demo-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($affiliate-ui-demo-theme);

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  background: linear-gradient(
    90deg,
    #d2e1f1 0%,
    #dbe7f3 0%,
    #f2f7f7 100%,
    #f2f7f7 100%
  );
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.02em;
}

.flex-row {
  display: flex;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.row-space-between {
  @extend .flex-row;
  justify-content: space-between;
}

.row-center-space-around {
  @extend .flex-row;
  justify-content: space-around;
}

.gap-2 {
  gap: 2px;
}

.gap-4 {
  gap: 4px;
}

.gap-6 {
  gap: 6px;
}

.gap-8 {
  gap: 8px;
}

.gap-10 {
  gap: 10px;
}

.gap-12 {
  gap: 12px;
}

.gap-14 {
  gap: 14px;
}

.gap-16 {
  gap: 16px;
}

.gap-18 {
  gap: 18px;
}

.gap-20 {
  gap: 20px;
}

.gap-40 {
  gap: 40px;
}

.gap-60 {
  gap: 60px;
}

.gap-80 {
  gap: 80px;
}

.gap-100 {
  gap: 100px;
}

.bold {
  font-weight: 700;
}
